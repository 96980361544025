import React, { useEffect } from 'react';
import './Mobile.css';
import AdComponent from '../../Components/AdComponent/AdComponent';
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import logo from "../../assets/images/logo.png";

function Mobile() {
    return (
        <>
            <div className='Main_Bg'>
                <div className='content-section'>
                    <div className="m-header">
                        <div className="container">
                            <div className="row m-0">
                                <div className="cent">
                                    <a href="https://multigamezone.com/">
                                        <img src={logo} alt="" height={60} />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='p-3'>
                        <AdComponent
                            client="ca-pub-3550658214779566"
                            slot="2918457438"
                            format="auto"
                            style={{ display: 'block', width: '100%', height: '280px' }}
                        />
                        <div className='px-2'>
                            <div className='custom-card mt-4 py-4'>
                                <div className='custom-heading h4'>Welcome to Multi Game Zone</div>
                                <a href="/home" >
                                    <button class="adbutton mt-4"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="text-white inline-block me-1.5 mb-1" height="23" width="23" xmlns="http://www.w3.org/2000/svg"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z"></path></svg><b class="text-[#ffff] text-xl ml-1">PLAY NOW</b></button>
                                </a>
                            </div>
                            <div className='custom-card mt-4 py-4'>
                                <div className='custom-heading h4'>Play free online games!</div>
                                <div className='custom-heading h4'>Pick your Favorite game category</div>
                                <a href="/home" ><button className='col-5 col-sm-4 col-lg-4 mt-4 ms-2 custom-button p-2 px-4'>Action</button></a>
                                <a href="/home" ><button className='col-5 col-sm-4 col-lg-4 mt-4 ms-2 custom-button p-2 px-4'>Puzzle</button></a>
                                <a href="/home" ><button className='col-5 col-sm-4 col-lg-4 mt-4 ms-2 custom-button p-2 px-4'>Racing</button></a>
                                <a href="/home" ><button className='col-5 col-sm-4 col-lg-4 mt-4 ms-2 custom-button p-2 px-4'>Casual</button></a>
                            </div>

                        </div>
                        <AdComponent
                            client="ca-pub-3550658214779566"
                            slot="5089325204"
                            format="auto"
                            style={{ display: 'block', width: '100%', height: '280px' }}
                        />
                    </div>
                    <div className='m-footer'>
                        <div className='d-flex justify-content-between'>
                            <a href="https://www.multigamezone.com/privacy-policy">Privacy-policy</a>
                            <div className='d-flex'>
                                <a href="https://www.facebook.com/share/Y1a4uGYSHegy1Fhd/?mibextid=qi2Omg" target='_blank' style={{ color: '#0866ff' }}><FaFacebookSquare size={20} /></a>
                                <a href="https://www.instagram.com/multi_gamezone?igsh=MWNzZHYzNWMwbHN4dw==" target='_blank' style={{ color: '#e41beb' }}><FaInstagram className='ms-3' size={20} /></a>
                            </div>
                            <a href="https://www.multigamezone.com/">multigamezone.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mobile